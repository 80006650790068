
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Loader extends Vue {
  @Prop() private msg!: string;

  mounted() {
    // document.body.classList.add('body-freeze')
  }
  destroyed() {
    // document.body.classList.remove('body-freeze')
  }
}
