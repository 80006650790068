
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
@Component
export default class PopUp extends Vue {
  @Prop({ default: false }) modalShow!: boolean;
  @Prop({ default: false }) message!: boolean;
  @Prop({ default: false }) messageArray!: [];
  @Prop({ default: false }) type!: boolean;

  showModal!: boolean;
  data() {
    return {
      showModal: this.modalShow,
    };
  }
  @Watch("modalShow")
  onModalShowChanged(newValue: boolean, oldValue: boolean) {
    this.showModal = this.modalShow;
  }
  close() {
    this.$emit("close-modal");
  }
}
