// import http from "../common-http";
import axios from "axios";

class FilterService {
    async getAll(Obj: any) {
        // console.log(Obj)
        return await axios.get('hrm/searching-hrm/', { params: Obj })
    }
    async getAllInvoice(Obj: any) {
        // console.log(Obj)
        return await axios.get('sales/searching-sale/', { params: Obj })
    }
    async getStatementOfAccount(Obj: any) {
        // console.log(Obj)
        return await axios.get('finance/searching-transaction-entry/', { params: Obj })
    }
    async getTrialBalance(Obj: any) {
        // console.log(Obj)
        return await axios.get('finance/search-trail-balance/', { params: Obj })
    }
    //User management Filters start
    async get_modules(Obj: any) {
        return await axios.get('api/get-screen-modules/', { params: Obj })
    }
    async get_screens(Obj: any) {
        return await axios.get('api/get-all-screens/', { params: Obj })
    }
    async get_users(Obj: any) {
        return await axios.get('api/get-all-users/', { params: Obj });
    }
    async get_groups(Obj: any) {
        return axios.get('api/create-group/', { params: Obj })
    }
    async get_user_permissions(Obj: any) {
        return await axios.get('api/get-userprofile/', { params: Obj });
    }
    async get_asset(Obj: any) {
        return await axios.get('asset/searching-assets/', { params: Obj })
    }
    async get_item_stock(Obj: any, id: any) {
        return await axios.get(`product/searching-item-stock/${id}`, { params: Obj })
    }
    async getPurhcase(Obj:any, type:string){
        return await axios.get(`purchase/searching-purchase/${type}/`,{params:Obj})
    }
}

export default new FilterService();
